import React from "react";
import "./src/pcss/index.pcss";
import { AnimatePresence, MotionConfig } from "framer-motion";
import { AppStateProvider } from "@state";
import loadable from "@loadable/component";
import brand from "@utils/brand";

const { site } = brand;
export const wrapRootElement = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>;
};

const LakotaLayout = loadable(() => import("@templates/LakotaLayout"));
const LakotaAcLayout = loadable(() => import("@templates/LakotaAcLayout"));
const LakotaGcLayout = loadable(() => import("@templates/LakotaGcLayout"));
const LgcaAcLayout = loadable(() => import("@templates/LgcaAcLayout"));
const LgcaLayout = loadable(() => import("@templates/LgcaLayout"));
const RomeroLayout = loadable(() => import("@templates/RomeroLayout"));
const Userback = loadable(() => import("@organisms/Userback"));

const Default = {
  lakota: LakotaLayout,
  lakotaAc: LakotaAcLayout,
  lakotaGc: LakotaGcLayout,
  romero: RomeroLayout,
  lgcaAc: LgcaAcLayout,
  lgca: LgcaLayout,
}[site];

export const wrapPageElement = ({ element, props }) => {
  const { location, pageContext } = props;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
      <Default location={location} pageContext={pageContext}>
        <MotionConfig transition={{ duration: 0.3, type: "tween" }}>
          <AnimatePresence
            mode={site === "lgca" ? "sync" : "wait"}
            initial={false}
          >
            {element}
          </AnimatePresence>
        </MotionConfig>
      </Default>
    </>
  );
};
