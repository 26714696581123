import resolveImage from "./resolveImage";

const resolveFundraiser = f => {
  const expDate = Date.parse(f.expDate);
  return {
    ...f,
    title: f.title,
    heading: f.title,
    fName: f.fName,
    lName: f.lName,
    goal: parseInt(f.goal, 10),
    expDate,
    image: resolveImage(f.backgroundImage0),
    copy: f.heading0,
    vanId: f.heading5,
  };
};

export default resolveFundraiser;
